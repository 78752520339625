import { default as _91id_93mKbZ6ripLMMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexrHZOX6URdcMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93oxSBMhYOXNMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexEXK5LSXNlnMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsoDihCtsE6kMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsdhWk3zSZQNMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as index2QjpfM1gxyMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexRVqJShGkueMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardkEWkWQhGvFMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/dashboard.vue?macro=true";
import { default as entrance2jFjQHoVHYMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/entrance.vue?macro=true";
import { default as indexC0iGi5o9bKMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/index.vue?macro=true";
import { default as loginWiSZoQjCBXMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/login.vue?macro=true";
import { default as indextXyiMMy5mxMeta } from "/home/forge/merchants.smake.com/releases/v1.40.1/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93mKbZ6ripLMMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexrHZOX6URdcMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93oxSBMhYOXNMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexEXK5LSXNlnMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsoDihCtsE6kMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsdhWk3zSZQNMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[id]/favorite-variants.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: index2QjpfM1gxyMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexRVqJShGkueMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrance2jFjQHoVHYMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginWiSZoQjCBXMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/[slug]/login.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.40.1/pages/index.vue")
  }
]